import * as React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CalloutBanner from "../components/calloutBanner"
import ResourceQuotes from "../components/resourceQuotes"
import Modal from "../components/modal"
import LinkList from "../components/linkList"
import PhotoGrid from "../components/photoGrid"

import "../scss/pages/resources.scss"

class ResourcesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      modalContent: { youtubeId: "", title: "" },
      value: "all",
    }
    this.handleModalClick = this.handleModalClick.bind(this);
    this.handleResourceChange = this.handleResourceChange.bind(this)
  }

  handleModalClick(e, item) {
    e.preventDefault()
    if (item) {
      this.setState(state => ({
        showModal: !state.showModal,
        modalContent: { youtubeId: item.youtubeId, title: item.title },
      }))
    } else {
      this.setState(state => ({
        showModal: !state.showModal,
        modalContent: { youtubeId: "", title: "" },
      }))
    }
  }

  handleResourceChange(e) {
    this.setState(state => ({ value: e.target.value }));
  }

  componentDidMount() {
    // Adding &nbsp;
    // Replacing &reg; elements
    const regElements = document.querySelectorAll(".video__title"); 
    regElements.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace('®', "<sup>&reg;</sup>");
    })

    const elText = document.querySelectorAll('#what-experts-say .intro, .carousel__content p, .video__title, .video__desc'); 
      elText.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace('farmers like you', 'farmers like&nbsp;you');
      el.innerHTML = el.innerHTML.replace('Rake, Iowa', 'Rake,&nbsp;Iowa');
      el.innerHTML = el.innerHTML.replace('Trust In Food', 'Trust&nbsp;In&nbsp;Food');
      el.innerHTML = el.innerHTML.replace('and benefits.', 'and&nbsp;benefits.');
      el.innerHTML = el.innerHTML.replace('Apply & Return', 'Apply &amp;&nbsp;Return');
      el.innerHTML = el.innerHTML.replace('2020 Seminar', '2020&nbsp;Seminar');
      el.innerHTML = el.innerHTML.replace('environmental impact.', 'environmental&nbsp;impact.');
    })

    // Setting reviews anchor
    if (document.location.hash === '#what-experts-say') {
      window.scrollTo({
        top: document.getElementById("what-experts-say").offsetTop,
        behavior: "smooth"
      })
    }

    // Add a video loop delay
    const video = document.getElementById('resource-vid');
    video.addEventListener('ended', function() {
      setTimeout(function() {
        video.currentTime = 0;
        video.play();
      }, 2000);
    }, false);
  }

  render() {
    // Component Content
    const contentfulCalloutBanner = get(
      this,
      "props.data.contentfulCalloutBanner"
    )
    const quoteList = get(this, "props.data.quoteList.listItems")
    const videoList = get(this, "props.data.videoList.listItems")
    const contentProductsList = get(this, "props.data.productsList")
    const contentEquipmentList = get(this, "props.data.equipmentList")
    const contentGuidesList = get(this, "props.data.guidesList")
    const contentCalculatorsList = get(this, "props.data.calculatorsList")
    // const contentVideosList = get(this, "props.data.videosList")

    // Images
    const heroImg = get(this, "props.data.heroImg")
    const heroVideo = get(this, "props.data.heroVideo")
    // const playBtnBlue = get(this, "props.data.playBtnBlue")
    const photosZip = get(this, "props.data.photosZip")
    const contentfulPhotoGrid = get(this, "props.data.photoGrid")

    return (
      <Layout bodyClass="resources">
        <Seo title="Resources" description="Access multiple resources for SIMPAS, SIMPAS-Applied Solutions (SaS) and SmartBox+ including videos, tech sheets and more." />

        <section className="padding--none">
          <video
            id="resource-vid"
            title={heroVideo.description}
            className="hero--video"
            autoPlay
            playsInline
            muted
            poster={heroImg.fluid.src}
            controlsList="nodownload nofullscreen"
            disablePictureInPicture
          >
            <source src={heroVideo.file.url} type="video/mp4" />
          </video>
        </section>

        <section id="resources-intro">
          <div className="container text-center flow--thick">
            <div className="container--width-sm flow--thin">
              <h1>Resources</h1>
              <p>
                Find all of the information, updates and resources you need for
                SIMPAS-applied Solutions™, the SIMPAS<sup>®</sup> application system and
                the SmartBox+™ application system, including videos, tech sheets
                and&nbsp;more.
              </p>
              <br />
              <p><strong>Learn how SIMPAS-applied Solutions™ Can Save You Money and Increase&nbsp;Yield.</strong></p>
              <div className="text-center">
                <a className="btn" href="/contact-us">
                  Connect with a Specialist
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="bg--gray">
          <div className="container text-center flow--thick">
            <h2 className="text--default">Videos</h2>
            <div className="row flow--sm">
              {videoList.map((item, index) => {
                return (
                  <div className="col-12 col-md-4 video__wrapper" key={item.id}>
                    <div className="video flow--thin">
                      {/* Video Thumbnail */}
                      <Img
                        fluid={item.thumbnail.fluid}
                        className="video__img"
                        alt={item.thumbnail.description}
                      />

                      {/* Video Title */}
                      <a
                        className="video__title basic-link"
                        href="#!"
                        onClick={e => this.handleModalClick(e, item)}
                        dangerouslySetInnerHTML={{__html: item.title}}
                      >
                        {/*<img
                          src={playBtnBlue.fluid.src}
                          className="video__icon"
                          alt=""
                        />*/}
                      </a>

                      {/* Video Description */}
                      <p className="video__desc" dangerouslySetInnerHTML={{__html: item.description.description}}>
                      </p>
                    </div>
                  </div>
                )
              })}
              <p className="center space--above">For mp4 files of videos, please <a href="mailto:info@simpas.com" className="basic-link">contact us</a>.</p>
            </div>
          </div>
        </section>
        <section>
          <div className="container text-center flow--thick">

            <div className="flow--thick">
              <h2 className="text--default">Downloads &amp; Tools</h2>
              <div id="tools-dropdown-cont">
                <select id="tools-dropdown" name="tools-dropdown" readOnly onChange={this.handleResourceChange} value={this.state.value}>
                  <option value="all">ALL</option>
                  <option value="equipment">Equipment Information Sheets</option>
                  <option value="guides">Equipment User Guides</option>
                  <option value="calculators">Calculators</option>
                  <option value="products">Product Information Sheets</option>
                  {/*<option value="videos">Videos</option>*/}
                </select>
              </div>
              <div className={this.state.value==="equipment" ? "show-group lists-group" : (this.state.value === "all" ? "lists-group show-group" : "lists-group")} id="equipment">
                <h3>Equipment Information Sheets</h3>
                <LinkList data={contentEquipmentList} />
              </div>
              <div className={this.state.value==="guides" ? "show-group lists-group" : (this.state.value === "all" ? "lists-group show-group" : "lists-group")} id="guides">
                <h3>Equipment User Guides</h3>
                <LinkList data={contentGuidesList} />
              </div>
              <div className={this.state.value==="calculators" ? "show-group lists-group" : (this.state.value === "all" ? "lists-group show-group" : "lists-group")} id="calculators">
                <h3>Calculators</h3>
                <LinkList data={contentCalculatorsList} />
              </div>
              <div className={this.state.value==="products" ? "show-group lists-group" : (this.state.value === "all" ? "lists-group show-group" : "lists-group")} id="products">
                <h3>Product Information Sheets</h3>
                <LinkList data={contentProductsList} />
              </div>
              {/*<div className={this.state.value==="videos" ? "show-group lists-group" : (this.state.value === "all" ? "lists-group show-group" : "lists-group")} id="videos">
                <h3>Videos</h3>
                <LinkList data={contentVideosList} />
              </div>*/}
            </div>
          </div>
          <div className="container text-center flow--thick">
            <hr/>
            <h2>Photos</h2>
            <PhotoGrid data={contentfulPhotoGrid} />
            <div className="link-group">
              <a
                href={photosZip.file.url}
                className="btn"
                download={photosZip.file.url}
              >
                Download All Images
              </a>
              {/* <small>ZIP (22.47 MB)</small> */}
            </div>
          </div>
        </section>

        <section className="bg--gray reviews" id="what-experts-say">
          <div className="text-center flow--thick">
            <div className="container flow--thin">
              <h2>What Experts Say</h2>
            </div>
            <div className="container-lg">
              <div className="text-left">
                <div className="flow--thick">
                  {quoteList.map(item => {
                    return (
                      <div className="resource-quotes" key={item.id}>
                        <ResourceQuotes
                          img={item.quoteAuthorImage}
                          desc={item.quoteText.quoteText}
                          author={item.quoteAuthor}
                          key={item.id}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>

        <CalloutBanner data={contentfulCalloutBanner} />

        {/* Modal */}
        <Modal
          onClose={e => this.handleModalClick(e)}
          show={this.state.showModal}
          title={this.state.modalContent.title}
        >
          {this.state.youtubeId !== "" && (
            <div className="iframe__wrapper">
              <iframe
                width="100%"
                height="100%"
                src={
                  "https://www.youtube.com/embed/" +
                  this.state.modalContent.youtubeId + "?autoplay=1"
                }
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </Modal>
      </Layout>
    )
  }
}
export default ResourcesPage

export const ResourcesPageQuery = graphql`
  query ResourcesPageQuery {
    heroImg: contentfulAsset(title: { eq: "hero-resources" }) {
      id
      description
      fluid(maxWidth: 1500) {
        ...GatsbyContentfulFluid
      }
    }
    heroVideo: contentfulAsset(title: { eq: "Simpas Animation NewComponents Header" }) {
      id
      description
      file {
        url
      }
    }
    contentfulCalloutBanner(
      calloutTitle: { eq: "Don’t see what you’re looking for?" }
    ) {
      id
      backgroundColor
      calloutTitle
      calloutSubtitle
      linkText
      calloutLink {
        linkUrl
      }
    }
    videoList: contentfulList(listName: { eq: "Video List" }) {
      id
      listItems {
        ... on ContentfulVideo {
          id
          title
          youtubeId
          description {
            description
          }
          thumbnail {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
            description
          }
        }
      }
    }
    quoteList: contentfulList(listName: { eq: "Quote List" }) {
      id
      listItems {
        ... on ContentfulQuote {
          id
          quoteName
          quoteText {
            quoteText
          }
          quoteAuthor
          quoteAuthorImage {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
            description
          }
        }
      }
    }
    pdfIcon: contentfulAsset(title: { eq: "pdf-icon" }) {
      id
      description
      title
      fluid(maxWidth: 400, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
    playBtnBlue: contentfulAsset(title: { eq: "play-icon-blue" }) {
      id
      description
      fluid(maxWidth: 100, quality: 100) {
        src
      }
    }
    productsList: allContentfulListItem(sort: {fields: listItemTitle}, filter: {listItemType: {eq: "products"}}) {
      nodes {
        id
        listItemTitle
        listItemUrlText
        listItemUrl
        listItemType
        listItemFileSize
        listItemDescription
        listItemThumbnail {
          description
          file {
            url
          }
        }
        newTab
      }
    }
    equipmentList: allContentfulListItem(sort: {fields: listItemTitle}, filter: {listItemType: {eq: "equipment"}}) {
      nodes {
        id
        listItemTitle
        listItemUrlText
        listItemUrl
        listItemType
        listItemFileSize
        listItemDescription
        listItemThumbnail {
          description
          file {
            url
          }
        }
        newTab
      }
    }
    videosList: allContentfulListItem(sort: {fields: listItemTitle}, filter: {listItemType: {eq: "videos"}}) {
      nodes {
        id
        listItemTitle
        listItemUrlText
        listItemUrl
        listItemType
        listItemFileSize
        listItemDescription
        listItemThumbnail {
          description
          file {
            url
          }
        }
        newTab
      }
    }
    calculatorsList: allContentfulListItem(sort: {fields: listItemTitle}, filter: {listItemType: {eq: "calculators"}}) {
      nodes {
        id
        listItemTitle
        listItemUrlText
        listItemUrl
        listItemType
        listItemFileSize
        listItemDescription
        listItemThumbnail {
          description
          file {
            url
          }
        }
        newTab
      }
    }
    guidesList: allContentfulListItem(sort: {fields: listItemTitle}, filter: {listItemType: {eq: "guides"}}) {
      nodes {
        id
        listItemTitle
        listItemUrlText
        listItemUrl
        listItemType
        listItemFileSize
        listItemDescription
        listItemThumbnail {
          description
          file {
            url
          }
        }
        newTab
        orderNumber
      }
    }
    photoGrid: contentfulList(listName: { eq: "Resources Photos" }) {
      id
      listItems {
        ... on ContentfulListItem {
          id
          listItemImage {
            fluid(maxHeight: 194, maxWidth: 194, resizingBehavior: FILL, quality: 100) {
              ...GatsbyContentfulFluid
            }
            id
            description
          }
        }
      }
    }
    photosZip: contentfulAsset(title: { eq: "SimpasResourcesPhotos" }) {
      id
      file {
        url
      }
    }
  }
`
