import * as React from "react"
import get from "lodash/get"
import "../scss/components/linkList.scss"
import PDFIcon from "../images/icon-pdf.inline.svg"

class LinkList extends React.Component {
  render() {
    const list = get(this, "props.data.nodes[0].listItemType")
    // First sort by orderNumber if it exists, then sort by listItemTitle afterwards
    const listItems = get(this, "props.data.nodes").sort((a, b) => {
      if (a.orderNumber !== undefined && b.orderNumber !== undefined) {
          return a.orderNumber - b.orderNumber || a.listItemTitle.localeCompare(b.listItemTitle, 'en-US', { caseFirst: 'upper' });
      }
      return a.listItemTitle.localeCompare(b.listItemTitle, 'en-US', { caseFirst: 'upper' });
  });

    return (
      <ul className={"link-list " + list}>
        {listItems.map(item => (
          <li key={item.id} className="link-list__item">
            <div className="link-list__desc">
              <img alt={item.listItemThumbnail.description} src={item.listItemThumbnail.file.url} />
              <h4 dangerouslySetInnerHTML={{__html: item.listItemTitle}}></h4>
            </div>
            <div className="link-list__link">
              <p className="link-size">{item.listItemFileSize}</p>
              <a 
                href={item.listItemUrl}
                download={ (item.listItemUrlText == "Download" || item.listItemUrlText == "View or Download") ? item.listItemUrl : false}
                target={item.newTab && "_blank"} 
                rel={item.newTab && "noopener noreferrer"}
                className={"link-" + item.listItemUrlText.toLowerCase().replace(/\s+/g, '-')}
              >{item.listItemUrlText}</a>
            </div>
          </li>
        ))}
      </ul>
    )
  }
}

export default LinkList
